:root {
  --headerHeight: 150px;
  --altVerticalSpace: 70px;
  --spaceAfterHeader: 100px;
}
@media (max-width: 1279px) {
  :root {
    --headerHeight: 124px;
  }
}
@media (max-width: 767px) {
  :root {
    --headerHeight: 110px;
    --spaceAfterHeader: 80px;
    --altVerticalSpace: 50px;
  }
}
:root {
  --deskWidth: 1496px;
  --deskSpace: 50px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
:root .section--maxiGallery {
  --deskWidth: 1000px;
}
@media (max-width: 1279px) {
  :root {
    --deskSpace: 40px;
  }
}
@media (max-width: 767px) {
  :root {
    --deskSpace: 20px;
  }
}
:root {
  --spacePart: 15px;
}
@media (max-width: 1279px) {
  :root {
    --spacePart: 15px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root {
  --spaceUnit: 35px;
}
@media (max-width: 1279px) {
  :root {
    --spaceUnit: 35px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 30px;
  }
}
:root {
  --spaceTotal: 50px;
}
@media (max-width: 1279px) {
  :root {
    --spaceTotal: 50px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 40px;
  }
}
:root {
  --js-breakpoint: breakpointLarge;
}
@media (max-width: 1279px) {
  :root {
    --js-breakpoint: breakpointMedium;
  }
}
@media (max-width: 767px) {
  :root {
    --js-breakpoint: breakpointSmall;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #fff;
  font-size: 18px;
  line-height: 1.44444444;
  letter-spacing: 0.03em;
  border: 1px solid #000;
  cursor: pointer;
  color: #000 !important;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 4px 14px;
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #E59E6C, #E59E6C);
  transition: all 0.3s;
}
@media (max-width: 1279px) {
  .button {
    font-size: 16px;
    line-height: 1.5;
  }
}
.button:hover {
  background-size: 100% 100%;
}
/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2024 MyFonts Inc. */
@font-face {
  font-family: "HelveticaNowProDisplay";
  font-style: normal;
  font-weight: 400;
  src: url('/extras/fonts/HelveticaNowProDisplayRegular/font.woff2') format('woff2'), url('/extras/fonts/HelveticaNowProDisplayRegular/font.woff') format('woff');
}
@font-face {
  font-family: "HelveticaNowProDisplay";
  font-style: normal;
  font-weight: 800;
  src: url('/extras/fonts/HelveticaNowProDisplayExtraBold/font.woff2') format('woff2'), url('/extras/fonts/HelveticaNowProDisplayExtraBold/font.woff') format('woff');
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: #E59E6C;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: 800;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  font-size: 60%;
  top: -0.5em;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 16px;
  font-family: 'HelveticaNowProDisplay', sans-serif;
  line-height: 1.375;
}
.unit caption {
  display: none;
}
.flag {
  background: #E59E6C;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.cbdModule {
  float: left;
  width: 100%;
}
#view .cbdModule.is-empty {
  display: none;
}
.cbDynamicContent__element {
  float: left;
  width: 100%;
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.area .unit {
  float: left;
  width: 100%;
  margin-top: var(--spaceUnit);
  margin-bottom: var(--spaceUnit);
  position: relative;
}
.unit__background {
  float: left;
  width: 100%;
}
.unit__content {
  float: left;
  width: 100%;
}
.unit__head,
.unit__body,
.unit__foot {
  float: left;
  width: 100%;
}
.unit--fold div.less,
.unit--fold div.more {
  float: left;
  width: 100%;
}
.unit--fold .ctrl {
  float: left;
  width: 100%;
}
.unit .part {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  position: relative;
}
.part__content {
  float: left;
  width: 100%;
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
#edit .part video {
  pointer-events: none;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
  margin-top: 6px;
  text-transform: uppercase;
  font-weight: 800;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
div.load a.load {
  display: inline-block;
  padding-left: 26px;
  background-size: 16px 16px;
  background-position: 0 3px;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-download-black.svg);
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid #847F75;
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.part.cb-youtube iframe,
.part.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.part.cb-share {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.part.cb-share .cb-service {
  float: left;
  margin-left: 16px;
}
.part.cb-share .cb-service:first-child {
  margin-left: 0;
}
.part.cb-share .cb-service * {
  float: left;
}
.part.cb-share .cb-service a {
  float: left;
  width: 50px;
  height: 50px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 10000px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.part.cb-share .cb-service a:hover {
  filter: invert(1);
}
.part.cb-share .cb-service.cb-service-facebook-share a {
  background-size: auto 24px;
  background-image: url(/images/cb-default/socialShare-facebook-black.svg);
}
.part.cb-share .cb-service.cb-service-twitter a {
  background-size: auto 18px;
  background-image: url(/images/cb-default/socialShare-twitter-black.svg);
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 18px;
  position: relative;
}
.list--bullet li:before {
  content: '-';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
#expo {
  float: left;
  width: 100%;
  position: relative;
}
#slides {
  float: left;
  width: 100%;
  height: 676px !important;
  max-width: 100% !important;
  max-height: 100% !important;
  position: relative;
  overflow: hidden;
}
.layout2 #slides {
  height: calc(676px + var(--headerHeight)) !important;
}
@media (max-width: 1279px) {
  #slides {
    height: 500px !important;
  }
  .layout2 #slides {
    height: calc(500px + var(--headerHeight)) !important;
  }
}
@media (max-width: 767px) {
  #slides {
    height: 400px !important;
  }
  .layout2 #slides {
    height: calc(400px + var(--headerHeight)) !important;
  }
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.onload-animation #slides img {
  animation-name: zoomInOut;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.3, 0, 0.7, 1);
}
@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
#expo div.link {
  display: none;
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  background: #E59E6C;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#expo a.link.prev {
  left: 20px;
}
#expo a.link.next {
  right: 20px;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #E59E6C;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'HelveticaNowProDisplay', sans-serif;
  line-height: 1.42857143;
  color: #000;
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #E59E6C;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #E59E6C;
  font-size: 16px;
  font-weight: 800;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  background-color: #fff;
  font-size: 18px;
  line-height: 1.44444444;
  letter-spacing: 0.03em;
  border: 1px solid #000;
  cursor: pointer;
  color: #000 !important;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 4px 14px;
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #E59E6C, #E59E6C);
  transition: all 0.3s;
  line-height: 1.42857143;
}
@media (max-width: 1279px) {
  #disp .foot input,
  #disp .foot a {
    font-size: 16px;
    line-height: 1.5;
  }
}
#disp .foot input:hover,
#disp .foot a:hover {
  background-size: 100% 100%;
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px !important;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1279px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px !important;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'HelveticaNowProDisplay', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
  text-transform: uppercase;
  font-weight: 800;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 50px;
    padding-top: 14px;
    padding-bottom: 14px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  min-height: 50px;
  padding: 5px 10px;
  appearance: none;
  border: 1px solid #000;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
#view .unit.form div.tick.pile span.name {
  display: none;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 5px;
  width: 16px;
  margin-right: 6px;
  height: 22px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 14px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'HelveticaNowProDisplay', sans-serif;
  font-size: 16px;
  line-height: 1.375;
  background: #F0F0F0;
  height: 50px;
  border: 1px solid #000;
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  width: 100%;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form .submit {
  float: right;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  background-color: #fff;
  font-size: 18px;
  line-height: 1.44444444;
  letter-spacing: 0.03em;
  border: 1px solid #000;
  cursor: pointer;
  color: #000 !important;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 4px 14px;
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #E59E6C, #E59E6C);
  transition: all 0.3s;
}
@media (max-width: 1279px) {
  .unit.form .submit {
    font-size: 16px;
    line-height: 1.5;
  }
}
.unit.form .submit:hover {
  background-size: 100% 100%;
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: #000;
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  background-color: #fff;
  font-size: 18px;
  line-height: 1.44444444;
  letter-spacing: 0.03em;
  border: 1px solid #000;
  cursor: pointer;
  color: #000 !important;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 4px 14px;
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #E59E6C, #E59E6C);
  transition: all 0.3s;
}
@media (max-width: 1279px) {
  .two-step-verification-container a {
    font-size: 16px;
    line-height: 1.5;
  }
}
.two-step-verification-container a:hover {
  background-size: 100% 100%;
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 10px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: 800;
}
.table td {
  padding: 5px 10px;
  vertical-align: top;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: 800;
}
@media (max-width: 767px) {
  .part--table .table {
    font-size: 2.08604954vw;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
@media (max-width: 1279px) {
  body.cb-toggle-target-active {
    overflow: hidden;
  }
}
#home {
  float: left;
  height: 96px;
}
@media (max-width: 1279px) {
  #home {
    height: 80px;
  }
}
@media (max-width: 1279px) {
  #home {
    height: 70px;
  }
}
.logo {
  width: auto;
  height: 100%;
}
#head {
  float: left;
  width: 100%;
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'HelveticaNowProDisplay', sans-serif;
  font-size: 16px;
  line-height: 1.375;
  color: #000;
  letter-spacing: 0.01em;
  overflow: hidden;
  hyphens: none;
  box-sizing: border-box;
  padding-top: var(--headerHeight);
}
.layout2 .wrapper,
.layout4 .wrapper {
  padding-top: 0;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: var(--deskMaxWidth);
  width: var(--deskWidth);
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.layout4 .section {
  display: none;
}
.section--header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
  background-color: #fff;
  transition: all 0.4s;
}
.layout2:not(.cb-toggle-target-active) .section--header:not(.cb-scroll-triggered--active) {
  background-color: transparent;
}
.section--multimood {
  z-index: 2;
}
.section--sticky {
  padding-top: var(--headerHeight);
  margin-top: calc(var(--headerHeight) * -1);
}
.section--sticky.cb-navi-fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  margin-top: 0;
}
.section--sticky.cb-navi-sticky-clone {
  opacity: 0;
  pointer-events: none;
}
.layout2 .section--pfad {
  display: none;
}
.section--footer {
  background-color: #F0EDE6;
  border-top: 1px solid #000;
  margin-top: calc(220px - var(--spaceTotal));
}
@media (max-width: 1279px) {
  .section--footer {
    margin-top: calc(160px - var(--spaceTotal));
  }
}
@media (max-width: 767px) {
  .section--footer {
    margin-top: calc(120px - var(--spaceTotal));
  }
}
.section--credits {
  background-color: #000;
  color: #fff;
}
.section--maxiGallery {
  display: none;
}
.layout4 .section--maxiGallery {
  display: block;
}
.section--apartmentFilter {
  z-index: 4;
}
.content {
  float: left;
  width: 100%;
  position: relative;
}
.section--header .content {
  box-sizing: border-box;
  padding: 27px var(--deskSpace);
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .section--header .content {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.section--sticky .content {
  background-color: #000;
  color: #fff;
  box-sizing: border-box;
  padding: 0 var(--deskSpace);
  display: flex;
  align-items: center;
  min-height: 76px;
}
.subcontent {
  float: left;
  width: 100%;
}
.headerclaim {
  position: absolute;
  left: 50%;
  top: 27px;
  z-index: 2;
  white-space: nowrap;
  transform: translateX(-50%);
}
.headerclaim a {
  text-decoration: none;
  font-weight: 800;
  color: #000 !important;
}
@media (max-width: 1279px) {
  .headerclaim {
    display: none;
  }
}
.headerlinks {
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
}
.headerlinks .meta.find {
  position: absolute;
  right: 100%;
  top: 0;
  margin-right: 20px;
  z-index: 2;
  width: 34px;
  height: 34px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-search-black.svg);
}
.headerlinks .meta.find:hover {
  background-image: url(/images/icon-search-apricot.svg);
}
@media (max-width: 767px) {
  .headerlinks .meta.find {
    margin-right: 10px;
    width: 22px;
    height: 22px;
    top: 6px;
  }
}
.moodcontent {
  position: absolute;
  left: var(--deskSpace);
  bottom: 190px;
  z-index: 2;
  width: 820px;
  max-width: var(--deskMaxWidth);
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 1279px) {
  .moodcontent {
    bottom: 100px;
  }
}
.pfad {
  position: absolute;
  left: 0;
  top: 14px;
  z-index: 5;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  font-size: 10px;
  line-height: 1.6;
  color: #847F75;
}
.pfad::-webkit-scrollbar {
  display: none;
}
.pfad a {
  float: left;
  text-decoration: none;
  text-transform: uppercase;
  margin: 0 6px;
  white-space: nowrap;
}
.pfad a:first-child {
  margin-left: 0;
}
.pfad a:last-child {
  margin-right: 0;
}
.footcontent {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  line-height: 1.44444444;
  letter-spacing: 0.03em;
  margin: 20px 0;
}
@media (max-width: 1279px) {
  .footcontent {
    font-size: 16px;
    line-height: 1.5;
  }
}
.footcontent a {
  text-decoration: none;
}
@media (max-width: 1279px) {
  .footcontent {
    flex-direction: column;
  }
}
.footunit {
  float: left;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1279px) {
  .footunit {
    width: 100%;
  }
}
@media (max-width: 1279px) {
  .footunit--2 {
    margin-top: 60px;
  }
}
.footpart {
  float: left;
}
@media (max-width: 1279px) {
  .footpart {
    width: 50%;
    margin-left: 0 !important;
  }
}
@media (max-width: 767px) {
  .footpart {
    width: 100%;
    margin-top: 60px;
  }
  .footpart:first-child {
    margin-top: 0;
  }
}
.footunit--1 .footpart--2 {
  margin-left: 180px;
}
@media (max-width: 767px) {
  .footunit--1 .footpart--2 {
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }
}
.footunit--2 .footpart--2 {
  margin-left: 60px;
  width: 260px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.vcard {
  float: left;
}
.vcard .org {
  text-transform: uppercase;
}
.cleanNsafe {
  float: left;
  width: 106px;
}
#services {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#services .meta {
  float: left;
  text-transform: uppercase;
  margin-top: 6px;
}
#services .meta:first-child {
  margin-top: 0;
}
.reviews {
  float: left;
  margin-top: 20px;
}
.reviews .BadgeContainer__Inner-sc-1s0biz7-0 {
  padding: 0 !important;
}
.credits {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1279px) {
  .credits {
    flex-direction: column;
    align-items: flex-start;
    margin: 20px 0;
  }
}
.credits__part {
  float: left;
  display: flex;
  align-items: center;
}
@media (max-width: 1279px) {
  .credits__part {
    width: 100% !important;
  }
}
.credits__part--2 {
  width: 260px;
}
@media (max-width: 1279px) {
  .credits__part--2 {
    margin-top: 20px;
  }
}
.legal {
  float: left;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  min-height: 50px;
}
@media (max-width: 1279px) {
  .legal {
    min-height: 0;
  }
}
@media (max-width: 767px) {
  .legal {
    flex-direction: column;
    align-items: flex-start;
  }
}
.copyright {
  float: left;
  margin-right: 10px;
}
@media (max-width: 767px) {
  .copyright {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .legal__sep {
    display: none;
  }
}
#legal {
  float: left;
}
#legal .meta {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
}
@media (max-width: 767px) {
  #legal {
    font-size: 0;
    line-height: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
  }
  #legal .meta {
    font-size: 16px;
    line-height: 1.375;
    margin: 6px 0 0 !important;
  }
  #legal .meta:first-child {
    margin-top: 0 !important;
  }
}
.credits div.sub1 {
  float: left;
}
.credits div.sub1 > .item {
  float: left;
  margin-right: 20px;
}
.credits div.sub1 > .item.exit {
  margin-right: 0;
}
.credits div.sub1 > .item > .menu {
  text-transform: uppercase;
  text-decoration: none;
}
.credits div.sub1 > .item > .menu.path {
  color: #fff !important;
  font-weight: 800;
}
#social {
  float: left;
  margin-left: 36px;
  display: flex;
  align-items: flex-end;
}
#social .meta {
  float: left;
  margin-right: 20px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 26px;
  height: 26px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/social-instagram-white.svg);
}
#social .meta:last-child {
  margin-right: 0;
}
#social .meta:hover {
  background-image: url(/images/social-instagram-apricot.svg);
}
#social .meta.service_facebook {
  width: 14px;
  background-image: url(/images/social-facebook-white.svg);
}
#social .meta.service_facebook:hover {
  background-image: url(/images/social-facebook-apricot.svg);
}
#social .meta.service_youtube {
  height: 18px;
  background-image: url(/images/social-youtube-white.svg);
}
#social .meta.service_youtube:hover {
  background-image: url(/images/social-youtube-apricot.svg);
}
.bestPrice {
  float: left;
  width: 100%;
  display: none;
}
.layout2 .bestPrice,
.layout6 .bestPrice {
  display: block;
}
.bestPrice__content {
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 54px;
  min-height: 40px;
  background-size: 40px 40px;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: url(/images/bestPrice.svg);
}
.bestPrice__part {
  float: left;
  font-size: 18px;
  line-height: 1.44444444;
  letter-spacing: 0.03em;
}
@media (max-width: 1279px) {
  .bestPrice__part {
    font-size: 16px;
    line-height: 1.5;
  }
}
.bestPrice__part--title {
  color: #E59E6C;
  text-transform: uppercase;
  font-weight: 800;
}
.backlink--maxiGallery {
  position: fixed;
  left: 20px;
  top: 20px;
  z-index: 1400;
  width: 60px;
  height: 60px;
  border-radius: 10000px;
  cursor: pointer;
  background-size: 20px 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-left-black.svg);
}
.backlink--maxiGallery:hover {
  background-color: #F0EDE6;
}
@media (max-width: 1279px) {
  .backlink--maxiGallery {
    background-color: #F0EDE6;
  }
}
@media (max-width: 767px) {
  .backlink--maxiGallery {
    top: 15px;
    width: 50px;
    height: 50px;
  }
}
#cb-cookie-warning {
  position: fixed;
  left: 40px;
  bottom: 40px;
  max-width: calc(100vw - 100px);
  width: 400px;
  z-index: 2100;
  font-family: 'HelveticaNowProDisplay', sans-serif;
  font-size: 15px;
  line-height: 1.46666667;
  color: #000;
  background-color: #F0EDE6;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  border: 1px solid #000;
  padding: 30px;
  box-sizing: border-box;
}
#cb-cookie-warning a {
  text-decoration: underline;
}
#cb-cookie-warning.cb-cookie-warning--hidden {
  display: none;
}
@media (max-width: 1279px) {
  #cb-cookie-warning {
    left: 24px;
    bottom: 24px;
    max-width: calc(100vw - 48px);
  }
}
@media (max-width: 1279px) {
  #cb-cookie-warning {
    left: 16px;
    bottom: 16px;
    max-width: calc(100vw - 32px);
    padding: 16px;
  }
}
.cb-cookie-warning--container {
  float: left;
  width: 100%;
}
.cb-cookie-warning--text {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 30px;
}
@media (max-width: 767px) {
  .cb-cookie-warning--text {
    padding-bottom: 16px;
  }
}
.cb-cookie-warning--actions {
  float: left;
  width: 100%;
  display: flex;
  gap: 20px;
}
.cb-cookie-warning__button {
  float: left;
  width: 50%;
  background-color: #fff;
  font-size: 18px;
  line-height: 1.44444444;
  letter-spacing: 0.03em;
  border: 1px solid #000;
  cursor: pointer;
  color: #000 !important;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 4px 14px;
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #E59E6C, #E59E6C);
  transition: all 0.3s;
}
@media (max-width: 1279px) {
  .cb-cookie-warning__button {
    font-size: 16px;
    line-height: 1.5;
  }
}
.cb-cookie-warning__button:hover {
  background-size: 100% 100%;
}
#cb-cookie-warning__button--accept {
  font-weight: 800;
}
.bookNow {
  float: left;
  position: relative;
  z-index: 10;
}
.bookNow--mood {
  margin-top: 20px;
}
.bookNow__toggle {
  float: left;
  background-color: #fff;
  font-size: 18px;
  line-height: 1.44444444;
  letter-spacing: 0.03em;
  border: 1px solid #000;
  cursor: pointer;
  color: #000 !important;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 4px 14px;
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #E59E6C, #E59E6C);
  transition: all 0.3s;
}
@media (max-width: 1279px) {
  .bookNow__toggle {
    font-size: 16px;
    line-height: 1.5;
  }
}
.bookNow__toggle:hover {
  background-size: 100% 100%;
}
.bookNow__content {
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: -1px;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #000;
  display: none;
}
.bookNow.cb-toggle-target-active .bookNow__content {
  display: block;
}
.bookNow__item {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 14px;
  font-size: 15px;
  line-height: 1.2;
  border-bottom: 1px solid #000;
  text-decoration: none;
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #E59E6C, #E59E6C);
  color: #000 !important;
  transition: all 0.3s;
}
.bookNow__item:hover {
  background-size: 100% 100%;
}
.bookNow__item:last-child {
  border: none;
}
a {
  color: inherit;
  text-decoration: underline;
}
a:hover,
a:focus {
  color: #E59E6C;
}
h1 {
  font-size: 42px;
  line-height: 1.19047619;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-weight: normal;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}
h1 i {
  font-style: normal;
  color: #E59E6C;
}
.section--keyData h1 {
  text-shadow: none;
}
h2 {
  font-size: 42px;
  line-height: 1.19047619;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-weight: normal;
}
h2 strong {
  color: #847F75;
}
h2 i {
  font-style: normal;
  color: #E59E6C;
}
h3 {
  font-size: 18px;
  line-height: 1.44444444;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-weight: normal;
}
@media (max-width: 1279px) {
  h3 {
    font-size: 16px;
    line-height: 1.5;
  }
}
h3 i {
  font-style: normal;
  color: #E59E6C;
}
.loud {
  font-size: 18px;
  line-height: 1.44444444;
  letter-spacing: 0.03em;
}
@media (max-width: 1279px) {
  .loud {
    font-size: 16px;
    line-height: 1.5;
  }
}
.skew {
  display: block;
  background-color: #F0EDE6;
  border: 1px solid #000;
  padding: 10px 20px;
  position: relative;
}
.skew:after {
  content: '';
  position: absolute;
  left: 14px;
  top: 100%;
  border-style: solid;
  border-color: transparent;
  border-top-color: #000;
  border-width: 16px 8px 0 8px;
}
.part--skewColorOrange .skew {
  background-color: #E59E6C;
}
.pale {
  font-size: 12px;
  line-height: 1.5;
}
.area--one {
  display: flex;
  flex-wrap: wrap;
}
.area--one.area1 {
  margin-top: calc(var(--spaceAfterHeader) - var(--spaceTotal));
}
.layout5 .area--one.area1 {
  margin-top: 0;
}
.area--one .unitOne--smallSpaceDown {
  margin-bottom: calc((var(--spaceTotal) - var(--spacePart)) * -1);
}
.area--one .unitThree {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.area--one .unitThree .unit__body {
  margin-top: calc(var(--spacePart) * -1);
  margin-bottom: calc(var(--spacePart) * -1);
}
.area--one .unitFour {
  border-bottom: 1px solid #000;
  margin-bottom: var(--spaceTotal);
  padding-bottom: calc(var(--altVerticalSpace) - var(--spacePart));
}
.area--one .unitFour + .unitFour + .unitFour + .unitFour {
  margin-top: calc(((var(--spaceTotal) + var(--spacePart)) - var(--altVerticalSpace)) * -1);
}
.area--one .unitFour .pict {
  aspect-ratio: 1.41176471;
}
.area--one .unitFour .pict * {
  height: 100%;
}
.area--one .unitFour .pict img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
@media (max-width: 1279px) {
  .area--one .unitFour + .unitFour + .unitFour {
    margin-top: calc(((var(--spaceTotal) + var(--spacePart)) - var(--altVerticalSpace)) * -1);
  }
}
@media (max-width: 767px) {
  .area--one .unitFour + .unitFour {
    margin-top: calc(((var(--spaceTotal) + var(--spacePart)) - var(--altVerticalSpace)) * -1);
  }
}
.area--one .unit--fold {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.area--one .unit--fold + .unit--fold {
  margin-top: calc(var(--spaceTotal) * -1);
}
.area--one .unit--fold + .unit--fold .fold-toggle {
  padding-top: 30px;
}
.area--one .unit--fold div.less .part {
  margin-top: 0;
  margin-bottom: 0;
}
.area--one .unit--fold div.more {
  display: flex !important;
  flex-wrap: wrap;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 0 26px;
  background-size: 100% 3px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #000, #000);
  height: 0;
  overflow: hidden;
  transition: all 0.6s;
}
.area--one .unit--fold div.more:before {
  content: '';
  float: left;
  width: 100%;
  margin-top: calc(40px - var(--spacePart));
}
.area--one .unit--fold div.more:after {
  content: '';
  float: left;
  width: 100%;
  margin-top: calc(40px - var(--spacePart));
}
.area--one .unit--fold div.more .part {
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.2s;
}
.area--one .unit--fold .fold-toggle {
  display: block;
  text-decoration: none;
  border-bottom: 1px solid #000;
  padding-bottom: 6px;
  color: #000;
  position: relative;
}
.area--one .unit--fold .fold-toggle:after {
  content: '';
  position: absolute;
  right: 14px;
  bottom: 10px;
  border-style: solid;
  border-color: transparent;
  border-top-color: #000;
  border-width: 16px 8px 0 8px;
  transition: all 0.4s;
}
.area--one .unit--fold .fold-toggle:hover {
  color: #E59E6C;
}
.area--one .unit--fold .fold-toggle.fold-toggle--open {
  color: #E59E6C;
}
.area--one .unit--fold .fold-toggle.fold-toggle--open:after {
  border-top-color: #E59E6C;
  transform: rotate(-180deg);
}
.area--one .unit--fold .unit__foot {
  display: none;
}
.area--one .unit--fold.unit--foldOpen div.more {
  height: var(--js-elementHeight);
}
.area--one .unit--fold.unit--foldOpen div.more .part {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.4s 0.2s;
}
@media (max-width: 767px) {
  .area--one .unit--fold div.more {
    padding: 0;
  }
}
.area--two {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.area--two .unitOne--small {
  max-width: 926px;
}
.unit--miniGallery,
.unit--maxiGallery {
  float: left;
  width: 100%;
}
#edit .unit--miniGallery.is-empty,
#edit .unit--maxiGallery.is-empty {
  min-height: 300px;
  background-color: rgba(229, 158, 108, 0.08);
  position: relative;
}
#edit .unit--miniGallery.is-empty:after,
#edit .unit--maxiGallery.is-empty:after {
  content: 'Galerie';
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  font-weight: 800;
  font-size: 50px;
  line-height: 1.2;
  letter-spacing: 0.03em;
  color: rgba(0, 0, 0, 0.2);
}
.unit--miniGallery .part *,
.unit--maxiGallery .part * {
  height: 100%;
}
.unit--miniGallery .part img,
.unit--maxiGallery .part img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.unit--miniGallery .part .placeholder,
.unit--maxiGallery .part .placeholder {
  padding-bottom: 0;
}
.unit--miniGallery {
  margin-top: calc((var(--spaceUnit) - var(--spacePart)) * -1);
  margin-bottom: var(--spaceTotal);
}
.unit--miniGallery .part {
  aspect-ratio: 1.47083333;
}
.unit--maxiGallery {
  margin-top: var(--spaceAfterHeader);
  margin-bottom: var(--spaceAfterHeader);
}
.unit--maxiGallery .part {
  aspect-ratio: 1.47083333;
}
.part--stickyText {
  float: left;
  width: 100%;
  margin: 10px 0;
}
.part--link .open {
  display: inline-block;
  background-color: #fff;
  font-size: 18px;
  line-height: 1.44444444;
  letter-spacing: 0.03em;
  border: 1px solid #000;
  cursor: pointer;
  color: #000 !important;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 4px 14px;
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #E59E6C, #E59E6C);
  transition: all 0.3s;
}
@media (max-width: 1279px) {
  .part--link .open {
    font-size: 16px;
    line-height: 1.5;
  }
}
.part--link .open:hover {
  background-size: 100% 100%;
}
.part--linkAlignBeside {
  width: auto !important;
  min-width: 60px;
  margin-right: 24px !important;
}
.text-section--skew {
  margin-bottom: 15px;
}
.text-section--skew + .text-section--pale {
  margin-top: 10px;
  box-sizing: border-box;
  padding-left: 20px;
}
.togglenavigation {
  float: left;
  position: relative;
  cursor: pointer;
  font-size: 18px;
  line-height: 1.44444444;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  text-decoration: none;
  padding-right: 50px;
  color: #000 !important;
}
@media (max-width: 1279px) {
  .togglenavigation {
    font-size: 16px;
    line-height: 1.5;
  }
}
.tline {
  position: absolute;
  top: 3px;
  right: 0;
  width: 40px;
  height: 4px;
  background-color: #000;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.tline--2,
.tline--3 {
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 3px;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateY(-10px);
}
body.cb-toggle-target-active .tline--2 {
  transform: translateY(-50%) rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: translateY(-50%) rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateY(10px);
}
.navigation {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  background-color: #fff;
  height: 0;
  overflow: hidden;
  transition: all 0.6s;
}
body.cb-toggle-target-active .navigation {
  height: var(--js-elementHeight);
}
@media (max-width: 1279px) {
  body.cb-toggle-target-active .navigation {
    height: calc((var(--vh, 1vh) * 100) - var(--headerHeight));
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  body.cb-toggle-target-active .navigation::-webkit-scrollbar {
    display: none;
  }
}
.navigation__wrapper {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 0 var(--deskSpace);
}
.navigation__content {
  float: right;
  margin-top: 20px;
  margin-bottom: var(--altVerticalSpace);
  display: flex;
  align-items: flex-start;
}
@media (max-width: 1279px) {
  .navigation__content {
    float: left;
    width: 100%;
    flex-direction: column;
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .navigation__content {
    margin-top: 40px;
  }
}
.navigation__unit {
  float: left;
}
@media (max-width: 1279px) {
  .navigation__unit {
    width: 100% !important;
  }
}
.navigation__unit--2 {
  width: 166px;
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.2s;
}
body.cb-toggle-target-active .navigation__unit--2 {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.4s 0.5s;
}
@media (max-width: 1417px) {
  .navigation__unit--2 {
    width: auto;
  }
}
@media (max-width: 1279px) {
  .navigation__unit--2 {
    margin-top: 60px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 40px;
  }
}
@media (max-width: 767px) {
  .navigation__unit--2 {
    margin-top: 40px;
  }
}
.languageNavi,
.mainNavi {
  float: left;
}
@media (max-width: 1279px) {
  .languageNavi,
  .mainNavi {
    width: 100%;
  }
}
.languageNavi div.sub1 {
  float: left;
}
.languageNavi div.sub1 > .item {
  float: left;
  margin-right: 20px;
}
.languageNavi div.sub1 > .item.exit {
  margin-right: 0;
}
.languageNavi div.sub1 > .item > .menu {
  text-transform: uppercase;
  text-decoration: none;
}
.languageNavi div.sub1 > .item > .menu.path {
  color: #000 !important;
  font-weight: 800;
}
div.navi {
  float: left;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
.mainNavi div.sub2 {
  float: left;
  display: flex;
}
.mainNavi div.sub2 > .item {
  min-width: 166px;
  margin-right: 26px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.2s;
}
.mainNavi div.sub2 > .item > .menu {
  float: left;
  font-size: 18px;
  line-height: 1.44444444;
  letter-spacing: 0.03em;
  text-decoration: none;
  font-weight: 800;
  text-transform: uppercase;
}
@media (max-width: 1279px) {
  .mainNavi div.sub2 > .item > .menu {
    font-size: 16px;
    line-height: 1.5;
  }
}
.mainNavi div.sub2 > .item > .menu:hover,
.mainNavi div.sub2 > .item > .menu:focus,
.mainNavi div.sub2 > .item > .menu.path {
  color: #E59E6C;
}
body.cb-toggle-target-active .mainNavi div.sub2 > .item {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.4s 0.2s;
}
body.cb-toggle-target-active .mainNavi div.sub2 > .item + .item {
  transition: all 0.4s 0.25s;
}
body.cb-toggle-target-active .mainNavi div.sub2 > .item + .item + .item {
  transition: all 0.4s 0.3s;
}
body.cb-toggle-target-active .mainNavi div.sub2 > .item + .item + .item + .item {
  transition: all 0.4s 0.35s;
}
body.cb-toggle-target-active .mainNavi div.sub2 > .item + .item + .item + .item + .item {
  transition: all 0.4s 0.4s;
}
body.cb-toggle-target-active .mainNavi div.sub2 > .item + .item + .item + .item + .item + .item {
  transition: all 0.4s 0.45s;
}
@media (max-width: 1417px) {
  .mainNavi div.sub2 > .item {
    margin-right: 20px;
  }
}
@media (max-width: 1279px) {
  .mainNavi div.sub2 {
    width: calc(100% + 20px);
    margin: -30px -10px;
    flex-wrap: wrap;
  }
  .mainNavi div.sub2 > .item {
    margin: 30px 10px;
    width: calc((100% / 3) - 20px);
  }
}
@media (max-width: 767px) {
  .mainNavi div.sub2 {
    margin: -20px -10px;
  }
  .mainNavi div.sub2 > .item {
    margin: 20px 10px;
    width: calc(100% - 20px);
  }
}
.mainNavi div.sub3 {
  float: left;
  width: 100%;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
}
.mainNavi div.sub3 > .item {
  border-bottom: 1px solid #000;
}
.mainNavi div.sub3 > .item > .menu {
  text-decoration: none;
  padding: 2px;
}
.mainNavi div.sub3 > .item > .menu:hover,
.mainNavi div.sub3 > .item > .menu:focus,
.mainNavi div.sub3 > .item > .menu.path {
  color: #E59E6C;
}
@media (max-width: 1279px) {
  h2 {
    font-size: 34px;
  }
}
@media (max-width: 767px) {
  h1 {
    font-size: 30px;
    line-height: 1.2;
  }
  h2 {
    font-size: 30px;
    line-height: 1.2;
  }
}
@media (max-width: 767px) {
  .area--one .unitThree .unit__background {
    margin-bottom: calc(var(--spacePart) * 2);
  }
}
.unit--miniGallery .unit__body,
.unit--maxiGallery .unit__body {
  width: calc(100% + 20px);
  margin: -10px;
}
.unit--miniGallery .part,
.unit--maxiGallery .part {
  margin: 10px;
}
.unit--miniGallery .part {
  width: calc(50% - 20px);
}
.unit--miniGallery .part:first-child {
  width: calc(100% - 20px);
}
.unit--maxiGallery .part {
  width: calc(100% - 20px);
}
.keyData {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-right: 380px;
  margin-top: var(--spaceAfterHeader);
  margin-bottom: var(--spaceTotal);
  display: none;
}
.layout3 .keyData,
.layout5 .keyData {
  display: block;
}
@media (max-width: 1279px) {
  .keyData {
    padding-right: 0;
  }
}
.keyData__content {
  float: left;
  width: 100%;
  max-width: 926px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.keyData .part {
  float: left;
  width: 100%;
  margin-top: 4px;
}
.keyData .part.part--keyDataText,
.keyData .part.part--keyDataAddress,
.keyData .part.part--keyDataCapacity {
  margin-top: calc(var(--spacePart) * 2);
}
.part--keyDataMap.load a.load {
  float: left;
  padding-left: 40px;
  box-sizing: border-box;
  max-width: 100%;
  color: #E59E6C;
  font-weight: 800;
  background-size: 22px 22px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url(/images/keyData-map.svg);
}
.part--keyDataMap.load a.load:hover {
  filter: brightness(0);
}
.keyData .part__content {
  width: auto;
  box-sizing: border-box;
  padding-left: 40px;
  background-size: 22px 22px;
  background-position: 0 0;
  background-repeat: no-repeat;
}
.part--keyDataText .part__content {
  padding-left: 0;
}
.part--keyDataAddress .part__content {
  background-image: url(/images/keyData-address.svg);
}
.part--keyDataCapacity .part__content {
  background-image: url(/images/keyData-capacity.svg);
}
.part--keyDataRooms .part__content {
  background-image: url(/images/keyData-rooms.svg);
}
.part--keyDataSize .part__content {
  background-image: url(/images/keyData-size.svg);
}
.facilities {
  float: left;
  width: 100%;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.facilities__cover {
  float: left;
  width: 100%;
  max-width: 926px;
}
.facilities__content {
  float: left;
  width: calc(100% + 28px);
  margin-left: -14px;
  display: flex;
  flex-wrap: wrap;
}
.facilities__title {
  float: left;
  width: calc(100% - 28px);
  margin: 0 14px calc(var(--spacePart) * 2);
}
.facilities__title h3 {
  font-weight: 800;
}
.facilities__unit {
  float: left;
  width: calc(50% - 28px);
  margin-left: 14px;
  margin-right: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 767px) {
  .facilities__unit {
    width: calc(100% - 28px);
  }
}
.facilities__item {
  float: left;
  box-sizing: border-box;
  max-width: 100%;
  padding-left: 36px;
  background-size: 22px 22px;
  background-position: 0 0;
  background-repeat: no-repeat;
  margin-top: 4px;
}
.facilities__item:first-child {
  margin-top: 0;
}
.facilities__item.facilities__item--roomFacility1 {
  background-image: url(/images/facilities-garage.svg);
}
.facilities__item.facilities__item--roomFacility2,
.facilities__item.facilities__item--roomFacility3,
.facilities__item.facilities__item--roomFacility4 {
  background-image: url(/images/facilities-parkspace.svg);
}
.facilities__item.facilities__item--roomFacility5 {
  background-image: url(/images/facilities-elevator.svg);
}
.facilities__item.facilities__item--roomFacility6 {
  background-image: url(/images/facilities-washing-machine.svg);
}
.facilities__item.facilities__item--roomFacility7 {
  background-image: url(/images/facilities-tumble-dryer.svg);
}
.facilities__item.facilities__item--roomFacility8 {
  background-image: url(/images/facilities-dishwasher.svg);
}
.facilities__item.facilities__item--roomFacility9 {
  background-image: url(/images/facilities-sauna.svg);
}
.facilities__item.facilities__item--roomFacility10 {
  background-image: url(/images/facilities-cheminee.svg);
}
.facilities__item.facilities__item--roomFacility11 {
  background-image: url(/images/facilities-pets-allowed.svg);
}
.facilities__item.facilities__item--roomFacility12 {
  background-image: url(/images/facilities-pets-not-allowed.svg);
}
.facilities__item.facilities__item--roomFacility13 {
  background-image: url(/images/facilities-ac.svg);
}
.pageLinks {
  position: absolute;
  right: 0;
  top: var(--spaceAfterHeader);
  z-index: 2;
  display: none;
}
.layout3 .pageLinks {
  display: block;
}
@media (max-width: 1279px) {
  .pageLinks {
    position: relative;
    top: 0;
    float: left;
    margin-top: var(--spaceAfterHeader);
    margin-bottom: calc((var(--spaceAfterHeader) - var(--altVerticalSpace)) * -1);
  }
}
.pageLinks__content {
  float: left;
  display: flex;
  align-items: center;
}
.backlink--pageLinks {
  float: left;
  background-color: #fff;
  font-size: 18px;
  line-height: 1.44444444;
  letter-spacing: 0.03em;
  border: 1px solid #000;
  cursor: pointer;
  color: #000 !important;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 4px 14px;
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #E59E6C, #E59E6C);
  transition: all 0.3s;
}
@media (max-width: 1279px) {
  .backlink--pageLinks {
    font-size: 16px;
    line-height: 1.5;
  }
}
.backlink--pageLinks:hover {
  background-size: 100% 100%;
}
.pageShare {
  float: left;
  display: flex;
  align-items: center;
  margin-left: 24px;
}
.pageShare__title {
  float: left;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 1.44444444;
  letter-spacing: 0.03em;
  font-weight: 800;
}
@media (max-width: 1279px) {
  .pageShare__title {
    font-size: 16px;
    line-height: 1.5;
  }
}
.pageShare__item {
  float: left;
  margin-left: 14px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
.pageShare__item--facebook {
  width: 12px;
  height: 22px;
  background-image: url(/images/pageLink-facebook-black.svg);
}
.pageShare__item--facebook:hover {
  background-image: url(/images/pageLink-facebook-apricot.svg);
}
.pageShare__item--mail {
  width: 26px;
  height: 18px;
  background-image: url(/images/pageLink-mail-black.svg);
}
.pageShare__item--mail:hover {
  background-image: url(/images/pageLink-mail-apricot.svg);
}
.pageShare__item--whatsapp {
  width: 22px;
  height: 22px;
  background-image: url(/images/pageLink-whatsapp-black.svg);
}
.pageShare__item--whatsapp:hover {
  background-image: url(/images/pageLink-whatsapp-apricot.svg);
}
.ns-teaserWrapper--apartmentTeaser {
  float: left;
  width: 100%;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.ns-teaserContent {
  float: left;
  width: 100%;
}
.ns-teaserArea {
  float: left;
  width: calc(100% + 28px);
  margin-left: -14px;
  display: flex;
  flex-wrap: wrap;
  margin: calc((var(--altVerticalSpace) / 2) * -1) -14px;
}
.ns-teaser {
  float: left;
  width: calc((100% / 3) - 28px);
  margin-left: 14px;
  margin-right: 14px;
  margin: calc(var(--altVerticalSpace) / 2) 14px;
  border-bottom: 1px solid #000;
  padding-bottom: calc(var(--spacePart) * 2);
  color: inherit !important;
}
@media (max-width: 1279px) {
  .ns-teaser {
    width: calc(50% - 28px);
  }
}
@media (max-width: 767px) {
  .ns-teaser {
    width: calc(100% - 28px);
  }
}
.ns-teaser__content {
  float: left;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.ns-teaser__section {
  float: left;
  width: 100%;
}
.ns-teaser__section--2 {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.ns-teaser__part {
  float: left;
  margin-top: calc(var(--spacePart) * 2);
}
.ns-teaser__part--slider {
  width: 100%;
  margin-top: 0;
}
.ns-teaser__part--title {
  text-decoration: none;
  color: #000 !important;
}
.ns-teaser__part--price {
  margin-top: 0;
}
.ns-teaser__part--actions {
  margin-top: auto;
  padding-top: calc(var(--spacePart) * 2);
}
.ns-teaser__link {
  float: left;
  background-color: #fff;
  font-size: 18px;
  line-height: 1.44444444;
  letter-spacing: 0.03em;
  border: 1px solid #000;
  cursor: pointer;
  color: #000 !important;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 4px 14px;
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #E59E6C, #E59E6C);
  transition: all 0.3s;
}
@media (max-width: 1279px) {
  .ns-teaser__link {
    font-size: 16px;
    line-height: 1.5;
  }
}
.ns-teaser__link:hover {
  background-size: 100% 100%;
}
.apartmentFilter {
  float: left;
  width: 100%;
  margin-top: var(--spaceAfterHeader);
  margin-bottom: var(--spaceTotal);
  position: relative;
  z-index: 2;
}
.apartmentFilter__content {
  float: left;
  max-width: 100%;
  display: flex;
  background-color: #F0EDE6;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  border: 1px solid #000;
}
@media (max-width: 1279px) {
  .apartmentFilter__content {
    flex-wrap: wrap;
  }
  .apartmentFilter__content:after {
    content: '';
    float: left;
    width: 100%;
    order: 3;
    height: 1px;
    background-color: #000;
  }
}
@media (max-width: 767px) {
  .apartmentFilter__content:after {
    display: none;
  }
}
.apartmentFilter__unit {
  float: left;
  position: relative;
}
.apartmentFilter__unit:after {
  content: '';
  position: absolute;
  left: 0;
  top: 20px;
  width: 1px;
  height: calc(100% - 40px);
  background-color: #000;
}
@media (max-width: 767px) {
  .apartmentFilter__unit {
    width: 100% !important;
    border-top: 1px solid #000;
  }
  .apartmentFilter__unit:after {
    display: none !important;
  }
}
.apartmentFilter__unit--capacity:after {
  display: none;
}
@media (max-width: 1279px) {
  .apartmentFilter__unit--capacity {
    order: 4;
  }
}
@media (max-width: 1279px) {
  .apartmentFilter__unit--apartmentType {
    order: 1;
  }
  .apartmentFilter__unit--apartmentType:after {
    display: none;
  }
}
@media (max-width: 767px) {
  .apartmentFilter__unit--apartmentType {
    border-top: none;
    min-height: 61px;
  }
}
@media (max-width: 1279px) {
  .apartmentFilter__unit--facilities {
    order: 2;
  }
}
@media (max-width: 767px) {
  .apartmentFilter__unit--facilities {
    min-height: 61px;
  }
}
@media (max-width: 1279px) {
  .apartmentFilter__unit--price {
    order: 5;
  }
}
@media (max-width: 1279px) {
  .apartmentFilter__unit--actions {
    order: 6;
    width: 100%;
    border-top: 1px solid #000;
  }
  .apartmentFilter__unit--actions:after {
    display: none;
  }
}
.filterTitle {
  float: left;
  text-transform: uppercase;
  font-weight: 800;
  padding: 18px 26px 0;
  text-align: center;
  margin-bottom: 5px;
}
.filterCount {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 767px) {
  .filterCount {
    width: 100%;
  }
}
.filterCount_content {
  float: left;
  margin: 10px 26px 18px 26px;
  display: flex;
  align-items: center;
}
.filterCount_button {
  float: left;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: #fff;
  box-sizing: border-box;
  border: 1px solid #847F75;
  background-size: 8px 8px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-minus-black.svg);
}
.filterCount_button--plus {
  background-image: url(/images/icon-plus-black.svg);
}
.filterCount_amount {
  float: left;
  width: 30px;
  text-align: center;
  margin: 0 13px;
}
.filterDropdown {
  float: left;
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;
  margin-right: -1px;
  z-index: 3;
}
.filterDropdown.is-open {
  margin-bottom: -100000px;
  background-color: #F0EDE6;
  border-color: #000;
  margin-top: -1px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  z-index: 4;
}
@media (max-width: 1279px) {
  .filterDropdown {
    margin-left: -1px;
  }
}
@media (max-width: 767px) {
  .filterDropdown {
    width: 100%;
  }
}
.filterDropdown__toggle {
  float: left;
  text-transform: uppercase;
  font-weight: 800;
  padding: 18px 56px 10px 26px;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  color: #000 !important;
}
.filterDropdown__toggle:after {
  content: '';
  position: absolute;
  right: 26px;
  top: 25px;
  width: 14px;
  height: 8px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/chevron-down-black.svg);
}
.filterDropdown__toggle:hover {
  color: #E59E6C !important;
}
.filterDropdown__toggle:hover:after {
  background-image: url(/images/chevron-down-apricot.svg);
}
.filterDropdown.is-open .filterDropdown__toggle {
  color: #E59E6C !important;
}
.filterDropdown.is-open .filterDropdown__toggle:after {
  background-image: url(/images/chevron-down-apricot.svg);
  transform: rotate(-180deg);
}
@media (max-width: 1279px) {
  .filterDropdown__toggle {
    padding-bottom: 18px;
  }
}
.filterDropdown__content {
  float: left;
  box-sizing: border-box;
  padding: 0 26px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 0;
  overflow: hidden;
}
.filterDropdown.is-open .filterDropdown__content {
  height: auto;
  padding-bottom: 18px;
}
.filterDropdown__item {
  float: left;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
}
.filterDropdown__item:first-child {
  margin-top: 0;
}
.filterDropdown__shape {
  float: left;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 1px solid #847F75;
  background-color: #fff;
  position: relative;
}
.filterDropdown__shape:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: 8px 8px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-x-black.svg);
  transition: all 0.2s;
  transform: scale(0);
}
.filterDropdown__item.is-active .filterDropdown__shape:after {
  transform: scale(1);
}
.filterDropdown__value {
  float: left;
  line-height: 20px;
  text-decoration: none;
  margin-left: 10px;
  color: #000 !important;
}
.filterRange {
  float: left;
  display: flex;
  flex-direction: column;
}
@media (max-width: 767px) {
  .filterRange {
    width: 100%;
  }
}
.filterRange__content {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 26px 18px;
}
.filterRange__slider {
  float: left;
  width: 126px;
  position: relative;
}
@media (max-width: 767px) {
  .filterRange__slider {
    width: 100%;
  }
}
.rc-slider-rail {
  float: left;
  width: 100%;
  height: 2px;
  background-color: #000;
  margin: 5px 0;
}
.rc-slider-handle {
  position: absolute;
  top: 50%;
  margin-top: -6px;
  z-index: 2;
  width: 6px;
  height: 12px;
  background-color: #fff;
  cursor: pointer;
  box-sizing: border-box;
  border: 2px solid #000;
}
.rc-slider-handle-1 {
  left: 0;
}
.rc-slider-handle-2 {
  right: 0;
}
.filterRange__values {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filterActions {
  float: left;
  min-height: 100%;
  box-sizing: border-box;
  padding: 18px 26px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  .filterActions {
    width: 100%;
  }
}
.filterActions__button {
  float: left;
  background-color: #fff;
  font-size: 18px;
  line-height: 1.44444444;
  letter-spacing: 0.03em;
  border: 1px solid #000;
  cursor: pointer;
  color: #000 !important;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 4px 14px;
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #E59E6C, #E59E6C);
  transition: all 0.3s;
  font-weight: 800;
}
@media (max-width: 1279px) {
  .filterActions__button {
    font-size: 16px;
    line-height: 1.5;
  }
}
.filterActions__button:hover {
  background-size: 100% 100%;
}
@media (max-width: 767px) {
  .filterActions__button {
    width: 100%;
  }
}
.ns-apartmentSlider {
  float: left;
  width: 100%;
}
.ns-sliderWrapper--apartmentSlider {
  float: left;
  width: 100%;
  position: relative;
}
.ns-apartmentSlider .ns-slider__stage {
  float: left;
  width: 100%;
  display: flex;
}
.ns-apartmentSlider .ns-slide {
  float: left;
  width: 100%;
  flex-shrink: 0;
  margin-right: -100%;
  opacity: 0;
  position: relative;
  z-index: 1;
  transition: opacity 0.8s;
}
.ns-apartmentSlider .ns-slide.is-active {
  opacity: 1;
  z-index: 2;
}
.ns-sliderAnchor {
  float: left;
  width: 100%;
  aspect-ratio: 1.41176471;
}
.ns-sliderControl {
  position: absolute;
  top: 50%;
  width: 20px;
  height: 30px;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 3;
  cursor: pointer;
  background-size: 12px auto;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.ns-sliderControl--prev {
  left: 0;
  background-image: url(/images/chevron-left-black.svg);
}
.ns-sliderControl--next {
  right: 0;
  background-image: url(/images/chevron-right-black.svg);
}
/*# sourceMappingURL=./screen-small.css.map */